<template>
  <div class="admins admin-page p-12 ml-auto">
    <div class="container">
      <header class="admin-page-header grid grid-cols-3 items-center mb-10">
        <h1 class="text-3xl text-blue">Administradores</h1>
        <search class="mx-auto" v-model="search" />
        <div class="flex justify-end items-end">
          <Button @click="confirmationModal = 'message'" ghost :class="{'ml-auto opacity-0 pointer-events-none ml-auto': true, 'opacity-100 pointer-events-auto': selected.length}">Excluir selecionados</Button>
          <Button class="ml-10" :to="{ name: 'RegisterAdmin' }">Cadastrar</Button>
        </div>
      </header>

      <div class="admins-table admin-data-table bg-white rounded-lg pb-12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          show-select
          hide-default-footer
          disable-pagination
          :loading="loading"
          loading-text="Carregando... Aguarde"
          class="elevation-1"
        >
          <template v-slot:item.name="{ item }">
            <div @click="openPreview(item)">
              {{ item.name }}
              <span class="block text-xs opacity-60">{{ item.email }}</span>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="table-actions">
              <router-link :to="{ name: 'EditAdmin', params: { id: item.id } }" class="admins-table__actions admins-table__actions-edit w-8 h-8 mr-4"></router-link>
              <button class="admins-table__actions admins-table__actions-trash w-8 h-8" @click="remove(item)"></button>
            </div>
          </template>
        </v-data-table>

        <pagination :paginator="paginator" @load="loadItems" />
      </div>
    </div>

    <modal-admin-user :item="selectedItem" />
    <delete-register-modal :item="selectedItem" model="admins" @done="loadItems" />

    <modal-confirmation 
      :state="confirmationModal" 
      cancelbutton="Não" 
      confirmbutton="Sim" 
      :title="`Deseja realmente excluir ${selected.length} ${selected.length > 1 ? 'itens' : 'item'}?`" 
      v-on:confirm="deleteSelecteds"
      v-on:cancel="cancelDeletetion" />
  </div>
</template>

<script>
import api from '@/utils/api.js';
import paginator from '@/utils/paginator.js';

import { mapMutations } from 'vuex';

import Search from '@/components/admin/Search';
import Button from "@/components/Button";
import ModalAdminUser from '@/components/modals/AdminUser';
import ModalConfirmation from "@/components/modals/Confirmation";
import DeleteRegisterModal from '@/components/modals/DeleteRegisterModal';
import Pagination from '@/components/Pagination';

export default {
  name: "Admins",
  components: {
    Button,
    Search,
    Pagination,
    ModalAdminUser,
    ModalConfirmation,
    DeleteRegisterModal,
  },
  data() {
    return {
      search: '',
      selected: [],
      headers: [
        {
          text: 'Usuária',
          value: 'name',
          align: 'start',
        },
        { text: '', value: 'actions', sortable: false },
      ],
      items: [],
      loading: false,
      paginator: null,
      selectedItem: {},
      confirmationModal: 'close',
    }
  },
  watch: {
    search() {
      this.loadItems();
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    ...mapMutations(['openDeleteRegisterModal']),

    async loadItems(meta) {
      this.loading = true;
      this.paginator = await paginator('admins', meta, this.search);
      this.items = this.paginator.data;
      this.loading = false;
    },

    openPreview(item) {
      this.selectedItem = item;
      this.$store.dispatch('verticalModal/openModal');
    },

    remove(item) {
      this.selectedItem = item;
      this.openDeleteRegisterModal();
    },

    async deleteSelecteds() {
      this.confirmationModal = 'loading';

      const deletions = this.selected.map(async (item) => {
        await api.delete('/admins/'+ item.id);
      });

      try {
        await Promise.all(deletions);
      } catch(error) {
        console.log(error);
      }

      this.confirmationModal = 'close';      
      this.loadItems();
    },

    cancelDeletetion() {
      this.confirmationModal = 'close';
      this.selected = [];
    },
  }
};
</script>

<style lang="scss">
.admins-table {
  .v-data-table-header {
    th.text-start {
      &:last-child {
        span {
          background-image: none;
        }
      }
    }
  }

  tbody {
    td:nth-child(2) {
      width: 100%;
    }
  }
}
.admins-table__actions {
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 50%;

  &:focus {
    outline: none;
  }
}

.admins-table__actions-edit {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 15.25V19h3.75L15.81 7.94l-3.75-3.75L1 15.25zM3.92 17H3v-.92l9.06-9.06.92.92L3.92 17zM18.71 3.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29-.26 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 000-1.41z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
}

.admins-table__actions-trash {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");
}
</style>
