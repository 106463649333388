<template>
  <vertical-modal>
    <div class="flex flex-col py-20 px-10">
      <div class="flex flex-col">
        <div class="grid grid-cols-3 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Nome</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.name }}</div>
        </div>
        <div class="grid grid-cols-3 py-5 w-full">
          <div class="text-black text-opacity-70">E-mail</div>
          <div class="col-start-2 col-end-3 text-black">{{ item.email }}</div>
        </div>
      </div>

      <div class="flex justify-between mt-12">
        <Button v-if="item.id" ghost light :to="{ name: 'EditAdmin', params: { id: item.id } }">Atualizar</Button>

        <Button ghost light @click="openDeleteRegisterModal">
          <svg width="16" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>
        </Button>
      </div>
    </div>
  </vertical-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Button from "@/components/Button";
import VerticalModal from '@/components/VerticalModal';

export default {
  name: "AdminUser",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    Button,
    VerticalModal,
  },
  computed: {
    ...mapState('verticalModal', ['open']),
  },
  methods: {
    ...mapMutations(['openDeleteRegisterModal']),
  },
};
</script>

<style lang="scss"></style>
